import { CountUp } from "countup.js";
import React, { useEffect, useState } from "react";
import { withStore } from "retalk";
import { Carousel, Button } from "antd";
import "@/statics/css/style.css";
import "./index.less";

import LeftPhone from "../../statics/image/home/guide_phone.png";
import selfResearch from "../../statics/image/home/self-research.mp4";

import intro_0 from "../../statics/image/home/intro/transpool.png";
import intro_1 from "../../statics/image/home/intro/drivermanage.png";
import intro_2 from "../../statics/image/home/intro/arrange.png";
import intro_3 from "../../statics/image/home/intro/route.png";
import intro_4 from "../../statics/image/home/intro/tms.png";

import bannber_1 from "../../statics/image/home/banner/1.jpg";
import bannber_2 from "../../statics/image/home/banner/2.jpg";

import coLogo from "../../statics/image/home/co_img.png";

const Home = () => {
  const [appActive, setAppActive] = useState([false, false, false]);
  const [recuitActive, setRecuitActive] = useState([false, false, false]);

  const [isRunning, setRunning] = useState(false);

  const appList = ["司机端", "收派端", "中转端"];
  const recruitList = ["司机招募", "下单小程序"];

  // debounce 3秒
  const startCount = () => {
    const numberList = [60, 500, 2, 5000];
    ["count_1", "count_2", "count_3", "count_4"].forEach((count, i) => {
      const newCount = new CountUp(count, numberList[i], {
        startVal: i === 2 ? 0 : i === 3 ? 2000 : 1,
        duration: 3
      });
      setRunning(true);
      newCount.start();
    });
  };

  const selfStudy = [
    {
      name: intro_0,
      // 'transportPool',
      title: "运力池",
      subtitle: "海量运力 实时调度"
    },
    {
      name: intro_1,
      // 'driverManage',
      title: "司机履约管理",
      subtitle: "实时监控 安心守护"
    },
    {
      name: intro_2,
      // 'aiArrange',
      title: "智能排线",
      subtitle: "路径优化 高效集约"
    },
    {
      name: intro_3,
      // 'routeVisible',
      title: "路由可视化",
      subtitle: "实时路径 尽在掌握"
    },
    {
      name: intro_4,
      // 'customTms',
      title: "定制丰湃系统",
      subtitle: "客需定制 想您所需"
    }
  ];
  const hoverApp = (index, visible) => {
    const originActive = [...appActive];
    originActive[index] = visible;
    setAppActive(originActive);
  };
  const hoverRecruit = (index, visible) => {
    const originActive = [...recuitActive];
    originActive[index] = visible;
    setRecuitActive(originActive);
  };
  const banners = [
    {
      image: bannber_2,
      title: "即时城配运营服务",
      subtitle: "提供高时效、动态化城市配送服务"
    },
    {
      image: bannber_1,
      title: "丰湃系统支持端到端链路",
      subtitle: "智能化、安全、快捷的城市配送体验"
    }
  ];
  const io = new IntersectionObserver(entries => {
    startCount();
  });

  useEffect(() => {
    startCount();
    if (document.getElementById("bigdata")) {
      io.observe(document.getElementById("bigdata"));
    }
  }, [io]);

  return (
    <div className="homepage">
      <div className="home-banner">
        {/* <div className="center" style={{ position: "relative" }}>
        </div> */}
        <Carousel autoplay autoplaySpeed={5000}>
          {banners.map((banner, index) => {
            return (
              <div className="banner-wrap" key={index}>
                <img src={banner.image} style={{ width: "100%" }} />
                <div className="center">
                  <div className="text">
                    <p className="main-text">{banner.title}</p>
                    <p className="sub-text">{banner.subtitle}</p>
                    <div className="banner-chadan-wrap">
                      <a
                        href="http://fpdscm.com/merchant/index.html#/orderCenter"
                        target="_blank"
                      >
                        <Button type="primary">
                          <i className="icon-xiadan"></i>
                          在线下单
                        </Button>
                      </a>
                      <a
                        href="https://fpd.sf-express.com/static/page/delivery/routers.html#/"
                        target="_blank"
                        style={{ marginLeft: "22px" }}
                      >
                        <Button type="primary">
                          <i className="icon-chaxun"></i>
                          在线查单
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="product-wrap">
        <div className="center">
          <div className="block block_1">
            <div className="home-title" style={{ marginBottom: "36px" }}>
              产品与服务
            </div>
            <div className="new-content content">
              <div className="content-item"></div>
              <div className="content-item">
                <div className="modal"></div>
                <div className="text">
                  <p className="main-text">即时城配</p>
                  <p className="sub-text">
                    为餐饮、商超、生鲜、快消等本地生活类客户提供高时效、动态化城市配送服务
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="introduction-wrap">
        <video width="100%" autoPlay loop muted id="research-video">
          <source src={selfResearch} type="video/mp4" />
        </video>
        <div className="center">
          <div className="intro-cards-title">自主研发的物流管理系统</div>
          <ul className="intro-cards">
            {selfStudy.map((item, index) => (
              <li key={index}>
                <img src={item.name} />
                <div className="intro-title">{item.title}</div>
                <div className="intro-subtitle">{item.subtitle}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bigdata-wrap" id="bigdata">
        <div className="center">
          <ul className="bigdata-inner">
            <li>
              <div>
                <span id="count_1"></span>+
              </div>
              <div style={{ marginLeft: "-10px" }}>城市业务覆盖</div>
            </li>
            <li>
              <div>
                <span id="count_2"></span>+
              </div>
              <div style={{ marginLeft: "-23px" }}>员工数量</div>
            </li>
            <li>
              <div>
                <span id="count_3"></span>
              </div>
              <div>研发中心 北京成都</div>
            </li>
            <li>
              <div>
                <span id="count_4"></span>+
              </div>
              <div style={{ marginLeft: "-10px" }}>日运营车辆</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="client-wrap">
        <div className="center">
          <div className="block block_1">
            <div className="home-title">已服务客户</div>
            <div className="home-subtitle" style={{ marginBottom: "36px" }}>
              为合作伙伴提供专业，可靠的服务
            </div>
            <div className="co-logo">
              <img src={coLogo} />
            </div>
          </div>
        </div>
      </div>
      <div className="app-guide">
        <div className="center">
          <div className="app-guidewrap">
            <div className="homepage-left">
              <img src={LeftPhone} />
            </div>
            <div className="homepage-right">
              <h2>开启即时城配服务新体验</h2>
              <div>
                <h3>APP下载</h3>
                <ul className="app_ul_wrap">
                  {appList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onMouseOver={() => hoverApp(index, true)}
                        onMouseLeave={() => hoverApp(index, false)}
                      >
                        <span>
                          <b className={"appicon_" + index}></b>
                          {item}
                        </span>
                        <i
                          className={
                            "qrcode_" +
                            index +
                            (appActive[index] ? " active" : "")
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <h3 style={{ margin: "16px 0 10px" }}>招募/合作/下单</h3>
                <ul className="recruit_ul_wrap">
                  {recruitList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onMouseOver={() => hoverRecruit(index, true)}
                        onMouseLeave={() => hoverRecruit(index, false)}
                      >
                        <span>
                          <b className={"recruiticon_" + index}></b>
                          {item}
                        </span>
                        <i
                          className={
                            "recruitcode_" +
                            index +
                            (recuitActive[index] ? " active" : "")
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStore("home")(Home);
